import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './toast.scss';

const Toast = ({ message, type = 'success', onClose }) => {
    const [isFadingOut, setIsFadingOut] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsFadingOut(true);
            setTimeout(onClose, 300);
        }, 5000);

        return () => clearTimeout(timer);
    }, [onClose]);

    const handleClose = () => {
        setIsFadingOut(true);
        setTimeout(onClose, 300);
    };

    return (
        <div className={`toast toast-${type} ${isFadingOut ? 'toast-fade-out' : ''}`} role="alert" aria-live="assertive">
            <span>{message}</span>
            <button className="toast-close" onClick={handleClose} aria-label="Close toast">&times;</button>
        </div>
    );
};

Toast.propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['success', 'error']),
    onClose: PropTypes.func.isRequired,
};

export default Toast;
